































































































import Widget from '@/components/Widget/Widget.vue'
import { Component, Ref } from 'vue-property-decorator'
import ReportView from '@/models/ReportView'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
  },
})
export default class ReportViewHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement;

  public view: ReportView = new ReportView();

  public sort_by: string = 'created_at';

  public sort_desc: boolean = true;

  public query: string[] = [];

  public page_size: number = 25;

  public page: number = 1;

  public records: number = 0;

  public loading: boolean = false;

  public modal: any = {
    delete: false,
  };

  public fields: any = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ];

  public query_settings: any = {
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'user',
        key: 'owner_id',
        type: 'user',
      },
    ],
  };

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public views(context: any) {
    this.loading = true
    return ReportView.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false

      return result.data
    })
  }

  public confirmDelete(view: ReportView) {
    this.view = view
    this.modal.delete = true
  }

  public deleteView() {
    this.view.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public createView() {
    this.$router.push('/app/view')
  }

  public editView(id: string) {
    this.$router.push(`/app/view/${id}`)
  }

  public created() {
    this.records = this.views.length
  }
}
